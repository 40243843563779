<template>
  <div class="examStatistics">
    <el-scrollbar class="elScroll">
      <div class="liveHeader">
        <div class="lr-header">
          <span>考试情况统计</span>
        </div>
        <div class="lr-search">
          <el-form :inline="true" :model="formInline" class="demo-form-inline" label-width="80px" size="mini">
            <el-form-item label="时间维度" class="shortF">
              <el-select v-model="formInline.timeType" filterable placeholder="时间维度" @change="changeTimeType">
                <el-option
                  v-for="(item, key, index) in $common.timeStatisticType"
                  :key="index"
                  :label="item"
                  :value="Number(key)">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="统计时间段" label-width="90px">
              <template v-if="formInline.timeType == 1">
                <el-date-picker
                  v-model="dayTimeval"
                  type="daterange"
                  start-placeholder="开始日期"
                  range-separator="至"
                  end-placeholder="结束日期"
                  value-format="timestamp"
                  :picker-options="dayPickerOptions"
                >
                </el-date-picker>
              </template>
              <template v-else>
                <el-date-picker
                  v-model="monTimeVal"
                  type="monthrange"
                  range-separator="至"
                  start-placeholder="开始月份"
                  end-placeholder="结束月份"
                  value-format="timestamp"
                  :picker-options="monPickerOptions">
                </el-date-picker>
              </template>
            </el-form-item>
            <el-form-item label="统计省份" class="shortF">
              <el-select v-model="formInline.regionId" filterable placeholder="统计省份" @change="selectRegEvent">
                <el-option
                  v-for="(item, index) in regionData"
                  :key="index"
                  :label="item.name"
                  :value="Number(item.id)">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="统计学校" class="shortF">
              <el-select v-model="formInline.tenantId" filterable placeholder="统计学校">
                <el-option
                  v-for="(item, index) in schoolData"
                  :key="index"
                  :label="item.name"
                  :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-form>
          <div class="btn_area">
            <el-button size="mini" class="mainBtn" @click="onSubmit" plain>搜索</el-button>
            <el-button size="mini" type="info" plain @click="resetEvent">重置</el-button>
          </div>
        </div>
        <div class="echarts-area">
          <v-chart class="chart-school" :option="option" />
        </div>
      </div>

      <div class="liveHeader">
        <div class="lr-header">
          <span>考试详情列表</span>
        </div>
        <div class="table-area">
          <el-table
            :data="tableData"
            stripe
            border
            style="width: 100%"
            height="540"
            align="center"
            row-key='id'>
            <el-table-column
              v-for="(item, index) in columnList"
              :key="index"
              :prop="item.prop"
              :label="item.label ? item.label : '/'"
              :width="item.width"
              align="center"
              :sortable="item.prop != 'time' ? true : false"
            >
              <template slot-scope="scope">
                <span v-if="scope.row[scope.column.property] == null">/</span>
                <span v-else-if="scope.column.property == 'time'">{{scope.row.time == 'total' ? '总计' : scope.row.time }}</span>
                <span v-else-if="scope.column.property == 'participantRate' || scope.column.property == 'passRate'">{{scope.row[scope.column.property]}}%</span>
                <span v-else>
                  {{ scope.row[scope.column.property] }}
                </span>
              </template>
            </el-table-column>
            <el-table-column
              fixed="right"
              label="操作"
              width="120"
              align="center">
              <template slot-scope="scope">
                <el-button type="text" slot="reference" @click="watchDetails(scope.row)">查看明细</el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </el-scrollbar>

    <dialogDiyNew :isShow="isShowDetail" :dialogDiyData="dialogDetailData"
    @dialogEvent="dialogDetailEvent">
      <div slot="dialogContent" class="detail_area">
        <tableDetail ref="da"></tableDetail>
      </div>
    </dialogDiyNew>
  </div>
</template>

<script>
import tableDetail from './tableDetails'
const dialogDiyNew = () => import('@/components/commonModule/dialogDiyNew')
export default {
  name: 'examStatistics',
  components: {
    tableDetail,
    dialogDiyNew
  },
  data() {
    let that = this;
    async function lazyLoad(node, resolve) {
      const { level, value } = node;
      if (level == '0') return;
      let nodes = await that.getCities(value)
      nodes.forEach(item => item.leaf = level >= 1)
      setTimeout(() => {
        resolve(nodes);
      }, 800);
    }
    return {
      formInline: {
        beginTime: null,
        endTime: null,
        regionId: null,
        tenantId: null,
        timeType: 1
      },
      dayTimeval: [],
      dayPickerOptions: {
        shortcuts: [{
          text: '最近一周',
          onClick(picker) {
            let end = new Date().setHours(0, 0, 0, 0) + (24 * 60 * 60 * 1000 - 1);
            let start = new Date().setHours(0, 0, 0, 0);
            start = start - 3600 * 1000 * 24 * 7;
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近两周',
          onClick(picker) {
            let end = new Date().setHours(0, 0, 0, 0) + (24 * 60 * 60 * 1000 - 1);
            let start = new Date().setHours(0, 0, 0, 0);
            start = start - 3600 * 1000 * 24 * 14;
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近一个月',
          onClick(picker) {
            let end = new Date().setHours(0, 0, 0, 0) + (24 * 60 * 60 * 1000 - 1);
            let start = new Date().setHours(0, 0, 0, 0);
            start = start - 3600 * 1000 * 24 * 30;
            picker.$emit('pick', [start, end]);
          }
        }],
        // disabledDate(v) {
        // // 4、日期限制只能选当天和前30天
        //   let limitDate =
        //   v.getTime() < new Date().getTime() - 31 * 86400000 ||
        //   v.getTime() >= Date.now() + 8.64e7;
        //   // 86400000:一天的毫秒
        //   return limitDate;
        // }
      },
      monTimeVal: [],
      monPickerOptions: {
        shortcuts: [{
          text: '本月',
          onClick(picker) {
            picker.$emit('pick', [new Date(), new Date()]);
          }
        }, {
          text: '今年至今',
          onClick(picker) {
            const end = new Date();
            const start = new Date(new Date().getFullYear(), 0);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近六个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setMonth(start.getMonth() - 6);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近十二个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setMonth(start.getMonth() - 12);
            picker.$emit('pick', [start, end]);
          }
        }],
        // disabledDate(v) {
        //   let limitDate =
        //   v.getTime() < new Date().getTime() - 12 * 31 * 86400000 ||
        //   v.getTime() >= Date.now() + 8.64e7;
        //   return limitDate;
        // }
      },
      regionData: [],
      props: {
        lazy: true,
        value: 'code',
        label: 'name',
        lazyLoad
      },
      option: {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            label: {
              backgroundColor: '#6a7985'
            }
          }
        },
        legend: {
          bottom: '10%',
          left: 'center'
        },
        grid: {
          left: '3%',
          right: '4%',
          top: '4%',
          containLabel: true
        },
        xAxis: [
          {
            type: 'category',
            boundaryGap: true,
            data: [],
            axisLabel: {
              interval: 2
            }
          }
        ],
        yAxis: [
          {
            type: 'value'
          }
        ],
        series: [
          {
            name: '考试应考总次数',
            type: 'line',
            smooth: true,
            emphasis: {
              focus: 'series'
            },
            data: []
          },
          {
            name: '学生实际考试次数',
            type: 'line',
            smooth: true,
            emphasis: {
              focus: 'series'
            },
            data: []
          },
          {
            name: '学生考试及格数',
            type: 'line',
            smooth: true,
            emphasis: {
              focus: 'series'
            },
            data: []
          },
          {
            name: '考试日程发布数',
            type: 'line',
            smooth: true,
            emphasis: {
              focus: 'series'
            },
            data: []
          }
        ]
      },
      tableData: [],
      columnList: [
        {
          prop: "time",
          label: "统计时间"
        },
        {
          prop: "releaseScheduleNum",
          label: "日程发布数"
        },
        {
          prop: "releaseStudentNum",
          label: "发布人数"
        },
        {
          prop: "shouldNum",
          label: "应考总次数"
        },
        {
          prop: "participantNum",
          label: "实际考试数"
        },
        {
          prop: "participantRate",
          label: "参考率"
        },
        {
          prop: "awaitCheckedNum",
          label: "待查试卷数"
        },
        {
          prop: "invalidNum",
          label: "无效试卷数"
        },
        {
          prop: "reviewedNum",
          label: "已批阅试卷数"
        },
        {
          prop: "unreviewedNum",
          label: "未批阅试卷数"
        },
        {
          prop: "passNum",
          label: "及格试卷数"
        },
        {
          prop: "passRate",
          label: "及格率"
        }
      ],
      isShowDetail: false,
      dialogDetailData: {
        title: "查看明细",
        sizeStyle: { width: "1500px", height: "750px" }
      },
      schoolData: [],
    }
  },
  mounted() {
    this.getWeek()
    this.getProviceEvent()
  },
  methods: {
    // 获取一周内的时间段
    getWeek() {
      let end = new Date().setHours(0, 0, 0, 0) + (24 * 60 * 60 * 1000 - 1);
      let start = new Date().setHours(0, 0, 0, 0);
      start = start - 3600 * 1000 * 24 * 7;
      this.dayTimeval = [start, end]
      this.getExamStatisticData()
    },
    getExamStatisticData() {
      let obj = JSON.parse(JSON.stringify(this.formInline))
      obj.beginTime = this.formInline.timeType == 1 ? this.dayTimeval[0] : this.monTimeVal[0]
      obj.endTime = this.formInline.timeType == 1 ? this.dayTimeval[1] : this.monTimeVal[1]
      this.$request.examStatisticData(obj).then(res => {
        if (res.data.code == 0) {
          let data = JSON.parse(JSON.stringify(res.data.data))
          let list = data.list
          list.unshift(data.total)
          list[0].time = 'total'
          this.tableData = list
          this.getEchartsData(res.data.data.list)
        }
      })
    },
    getEchartsData(data) {
      this.option.xAxis[0].data = []
      this.option.series[0].data = []
      this.option.series[1].data = []
      this.option.series[2].data = []
      data.forEach((item, index) => {
        // let cTime = this.formInline.timeType == 1 ? this.$way.timestampReturnDate(item.createTime, 'timeMD') : this.$way.timestampReturnDate(item.createTime, 'dateM')
        this.option.xAxis[0].data.push(item.time)
        this.option.series[0].data.push(item.shouldNum)
        this.option.series[1].data.push(item.participantNum)
        this.option.series[2].data.push(item.passNum)
        this.option.series[3].data.push(item.releaseScheduleNum)
      })
      this.option.dataZoom = [
        {
          show: this.option.xAxis[0].data.length > 5 ? true : false,
          // show: true,
          height: 12,
          xAxisIndex: [0],
          bottom: "4%",
          // start: 0,
          // end: 100,
          maxValueSpan: 12,
          handleIcon:
            "path://M306.1,413c0,2.2-1.8,4-4,4h-59.8c-2.2,0-4-1.8-4-4V200.8c0-2.2,1.8-4,4-4h59.8c2.2,0,4,1.8,4,4V413z",
          handleSize: "110%",
          handleStyle: {
            color: "#d3dee5",
          },
          // textStyle: {
          //   color: "#666",
          // },
          textStyle: false,
          borderColor: "#90979c",
        }
      ]
    },
    changeTimeType() {
      this.dayTimeval = []
      this.monTimeVal = []
    },
    // 获取省份
    getProviceEvent() {
      this.$request.getPermissionProvinces({}, res => {
        if(res.code == 0) {
          this.regionData = res.data;
        }
      })
    },
    // 获取市
    getCities(parentCode) {
      return new Promise((resolve, reject) => {
        this.$request.getCity({parentCode}, res =>{
          if(res.code == 0) {
            resolve(res.data)
          }
        })
      })
    },
    // 获取学校
    getListData() {
      let obj = {
        page: 1,
        pageSize: 100,
        region: this.formInline.regionId
      }
      this.$request.getListData(obj, res => {
        if (res.code == 0) {
          this.schoolData = res.data.page.records
        }
      })
    },
    onSubmit() {
      if ((this.formInline.timeType == 1 && this.dayTimeval.length == 0) || (this.formInline.timeType == 2 && this.monTimeVal.length == 0)) {
        this.Warn('请选择统计时间段')
        return;
      }
      this.getExamStatisticData()
    },
    // 重置
    resetEvent() {
      Object.assign(this.$data.formInline, this.$options.data().formInline)
      this.getWeek()
    },
    // 排序
    sortEvent(column) {
      this.formInline.sort = column.order == "descending" ? -1 : column.order == "ascending" ? 1 : null
      this.getPlatformTableData()
    },
    // 查看详情
    watchDetails(row) {
      let timearea;
      let bTime; // 筛选区时间段初始时间
      let eTime; // 筛选区时间段结束时间
      let { timeType } = this.formInline
      if (row.time == 'total') {
        let start = timeType == 1 ? this.$way.timestampReturnDate(this.dayTimeval[0]) : this.$way.timestampReturnDate(this.monTimeVal[0], 'dateYM')
        let end = timeType == 1 ? this.$way.timestampReturnDate(this.dayTimeval[1]) : this.$way.timestampReturnDate(this.monTimeVal[1], 'dateYM')
        timearea = `${start}至${end}`
        bTime = timeType == 1 ? this.dayTimeval[0] : this.monTimeVal[0]
        eTime = timeType == 1 ? this.dayTimeval[1] : this.monTimeVal[1]
      } else {
        timearea = row.time
      }
      let obj = {
        ...row,
        timearea,
        timeType,
        bTime,
        eTime
      }
      this.dialogDetailEvent()
      this.$nextTick(() => {
        this.$refs.da.showEvent(obj)
      })
    },
    dialogDetailEvent() {
      this.isShowDetail = !this.isShowDetail
    },
    selectRegEvent() {
      this.formInline.tenantId = null
      this.getListData()
    }
  },
}
</script>

<style lang="less" scoped>
  .examStatistics {
    width: 100%;
    height: 100%;
    /deep/ .elScroll {
      height: 100%;
      .el-scrollbar__wrap {
        overflow-x: hidden;
      }
    }
    .liveHeader {
      background-color: #fff;
      box-sizing: border-box;
      margin-bottom: 20px;
      .lr-header {
        padding: 30px;
        border-bottom: 1px solid #EFEFEF;
        display: flex;
        align-items: center;
        & > span:nth-of-type(1) {
          font-size: 26px;
          color: #16222E;
          margin-right: 24px;
        }
      }
      .lr-search {
        box-sizing: border-box;
        padding: 30px;
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid #EFEFEF;
        .el-form {
          /deep/ .el-form-item {
            margin-bottom: 0;
          }
          .shortF {
            /deep/ .el-input__inner {
              width: 140px;
            }
          }
        }
      }
    }
    .echarts-area {
      padding: 30px;
      .chart-school {
        height: 360px;
        width: 100%;
        display: inline-block;
        margin: 0 auto;
      }
    }
    .table-area {
      box-sizing: border-box;
      padding: 20px;
    }
  }
</style>